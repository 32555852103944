<template>
  <Card :has-border="hasBorder" v-if="property">
    <div class="gap-2" :class="classes[layout].container">
      <img
        :src="assets.hero"
        :class="classes[layout].hero"
        class="rounded-md overflow-hidden object-cover @md:col-span-full transition-all"
      />
      <div
        class="gap-2 lg:gap-4"
        :class="classes[layout].content"
      >
        <img
          :src="assets.logo"
          class="overflow-hidden object-scale-down object-left-top"
          :class="classes[layout].logo"
        />
        <div class="w-full">
          <div class="flex flex-row divide-x">
            <ul class="whitespace-nowrap w-full text-left flex flex-col h-full justify-around pr-2">
              <li v-if="dates.checkInTime" class="uppercase text-zinc-500 text-[11px] font-medium tracking-widest leading-none">Check-In</li>
              <li 
                v-if="dates.checkInTime" 
                class="text-slate-800 font-bold md:font-medium tracking-wide"
                :class="classes[layout].time"
              >
                {{ dates.checkInTime }}
              </li>
              <li class="text-zinc-500 text-[11px] @md:text-sm">{{ dates.checkInDate }}</li>
            </ul>
            <ul class="whitespace-nowrap w-full text-right flex flex-col h-full justify-around pl-2">
              <li v-if="dates.checkOutTime" class="uppercase text-zinc-500 text-[11px] font-medium tracking-widest leading-none">Check Out</li>
              <li 
                v-if="dates.checkOutTime" 
                class="text-slate-800 font-bold md:font-medium tracking-wide"
                :class="classes[layout].time"
              >
                {{ dates.checkOutTime }}
              </li>
              <li class="text-zinc-500 text-[11px] @md:text-sm">{{ dates.checkOutDate }}</li>
            </ul>
          </div>
          <ul class="flex flex-row justify-between @md:mt-1 lg:mt-2">
            <li class="text-zinc-500 text-[11px] @sm:text-sm sm:text-sm">{{ dates.nights }}</li>
            <li class="text-zinc-800 text-[11px] @sm:text-sm sm:text-sm font-medium">{{ guests }}</li>
          </ul>
        </div>
      </div>
      <SButton v-if="!hideFooter" class="w-full text-lg py-5 @md:col-span-full" @click="goToCheckIn">
        Start check-in
      </SButton>
    </div>
  </Card>
</template>

<script lang="ts" setup>
import type { Reservation } from '~/grpc/proto/shackle/backend/backend_pb';

const config = useRuntimeConfig()
const { setTheme } = usePoweredByTheme()
const { reservation, property } = storeToRefs(useWebsiteStore())

interface StayDetailCardProps {
  hideFooter?: boolean
  hasBorder?: boolean
  layout?: 'default' | 'compact'
}

const emit = defineEmits(['start-checkin'])

const props = withDefaults(defineProps<StayDetailCardProps>(), {
  layout: 'default'
})

const hideFooter = toRef(props, 'hideFooter')

const formatDate = (date: Date) => {
  if (!date) return `-`
  const formatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  });

  try {    
    const [weekday, month, day] = formatter
      .formatToParts(date)
      .map(part => part.value)
      .filter(n => !['', ' ', ', '].includes(n))
    
    return `${weekday} ${day} ${month}`;
  } catch (error) {
    return `-`
  }

};

const goToCheckIn = () => {
  setTheme('dark')
  navigateTo('/check-in')
}

const imagePrefix = computed(() => {
  const supportedProperties = ['imperial-london', 'montcalm-london']
  // default to shackle property if property is not supported
  const name = property.value.name.includes(supportedProperties) ? property.value.name : 'properties/shackle'
  return `${config.public.webAssetUrl}/${name}/media`
})

const assets = computed(() => {
  const media = property.value?.media
  const includesId = (link: string) => link.includes(property.value?.name)

  return {
    hero: includesId(media.bannerImageLink) ? media.bannerImageLink : `${imagePrefix.value}/banner.jpg`,
    logo: includesId(media.logoImageLink) ? media.logoImageLink : `${imagePrefix.value}/logo.png`
  }
})

const classes = computed(() => {
  return {
    'default': {
      'container': 'grid',
      'hero': 'max-h-[240px] w-full',
      'content': 'my-2 grid @md:flex @md:col-span-1 @md:justify-between @lg:my-4',
      'logo': 'max-w-[50%] max-h-16',
      'time': 'text-2xl'
    },
    'compact': {
      'container' : 'flex lg:grid',
      'hero': 'w-16 h-16 @md:w-20 @md:h-20 flex-shrink-0 lg:h-[180px] lg:max-w-full lg:w-full',
      'content': 'flex justify-between w-full lg:flex-col',
      'logo': 'max-w-16 @md:max-w-36 flex-shrink-0 max-h-12 @md:max-h-16 lg:max-h-12 lg:max-w-full lg:mt-2',
      'time': 'md:text-2xl @md:text-xl'
    }
  }
})

const getTimezoneAdjustedCheckinOutTimes = (reservation: Reservation) => {
  const propertyTimeZone = reservation.propertyTimeZone

  if (!reservation) {
    throw Error('No reservation present for that claim.');
  }
  
  const formatter = (date: Date, timeZone: string) => {
    const intlFormatter = new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'short',
      timeStyle: 'short',
      timeZone
    });

    return intlFormatter.format(date).split(', ')[1];
  };

  const checkInToString = new Date(`${reservation.earliestCheckInTime}`)
  const checkOutToString = new Date(`${reservation.latestCheckOutTime}`)

  const tzAdjustedCheckInTime = formatter(checkInToString, propertyTimeZone);
  const tzAdjustedCheckOutTime = formatter(checkOutToString, propertyTimeZone);

  return {
    tzAdjustedCheckInTime,
    tzAdjustedCheckOutTime
  }
}

const dates = computed(() => {
  if (!reservation.value) return {}
  if (!property.value) return {}

  const checkIn = new Date(`${reservation.value.earliestCheckInTime}`.split('T')[0])
  const checkOut = new Date(`${reservation.value.latestCheckOutTime}`.split('T')[0])

  const { tzAdjustedCheckInTime, tzAdjustedCheckOutTime } = getTimezoneAdjustedCheckinOutTimes(reservation.value)

  const differenceInTime = checkOut.getTime() - checkIn.getTime();  
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const nightCount =  Math.max(0, Math.floor(differenceInDays));

  return {
    nights: `${nightCount} night${nightCount > 1 ? 's' : ''}`,
    checkInDate: formatDate(checkIn) ?? '-',
    checkOutDate: formatDate(checkOut) ?? '-',
    checkInTime: `${tzAdjustedCheckInTime}`,
    checkOutTime: `${tzAdjustedCheckOutTime}`
  }
})

const guests = computed(() => {
  if (!reservation.value) return ``
  const guestCount = reservation.value.guestCount
  return `${guestCount} guest${guestCount > 1 ? 's' : ''}`
})
</script>